import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./pages/landing";
import "./App.css";
import "./styles.sass";

function App() {
  return (
          <Router>
            <Routes>
              {/* Public routes */}
              <Route path="/" element={<Landing />}></Route>
            </Routes>
          </Router>
  );
}

export default App;
