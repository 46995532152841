import styled from "styled-components";

const ModalBootstrap = ({
  title,
  display,
  closeModal,
  submitButton,
  component,
  submitText,
  submitFunc,
  html
}) => {
  return (
    <>
      <Container
        className="modal"
        tabindex="-1"
        role="dialog"
        display={display}
      >
        <Modal className="modal-dialog  modal-dialog-centered" role="document">
          <Content className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title heading">{title}</h5>
              <ButtonContainer>
                <Button
                  type="button"
                  className="btn btn-dark"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeModal}
                >
                  <span aria-hidden="true">&times;</span>
                </Button>
              </ButtonContainer>
            </div>
            <Body className="modal-body">{component} {html && <span dangerouslySetInnerHTML={{ __html: html }}></span>}</Body>
            <div style={{ display: submitButton ? "block" : "none" }}>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={submitFunc}
                >
                  {submitText}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </Content>
        </Modal>
      </Container>
    </>
  );
};

export default ModalBootstrap;

const Container = styled.div`
  // display: ${(props) => (props?.display ? "block" : "none")};
  display: flex;
  justify-content: center;
  /* align-items: center;
    /* width: 100%; */
`;
const Modal = styled.div`
  position: relative;
  display: table; /* This is important */
  overflow-y: auto;
  overflow-x: auto;
  width: auto;
  min-width: 300px;
  @media (max-width: 480px) {
    /* margin: 0; */
    /* padding: 0; */
  }
  max-width: 90vh;
  max-height: 90vh;
`;
const Content = styled.div`
  background-color: rgba(74, 74, 74);
  border-radius: 8px;
  border: white 0.5px solid;
`;
const Body = styled.div`
  display: flex;
  justify-content: center;
`;
const ButtonContainer = styled.div`
  /* background-color: rgba(0,0,0); */
  aspect-ratio: 1/1;
  font-size: larger;
  border-radius: 8px;
  height: 35px;
  width: 35px;
`;
const Button = styled.button`
  /* background-color: rgba(0,0,0); */
  font-size: larger;
  color: white;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 8px;
  line-height: 0;
`;
