import styled from "styled-components"

const ModalContent = ({ modalContent }) => {
  return (
    <>
      {modalContent ? (
        <Container>
          <Container>
            <Img src={modalContent?.image}></Img>
            <Body className="mb-4">
              <Title className="fw-bold">{modalContent?.title}</Title>
              <Sub className="text-light text-muted lead">
                {modalContent?.sub}
              </Sub>
              <Text dangerouslySetInnerHTML={{ __html: modalContent?.text }}></Text>
            </Body>
          </Container>
        </Container>
      ) : null}
    </>
  )
}
export default ModalContent
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`
const Img = styled.img`
  max-width: 500px;
  width: 100%;
`
const Body = styled.div`
  max-width: 500px;
  width: 100%;
  padding: 0 1rem;
  @media (max-width: 567px) {
    padding: 0rem 1rem;
  }
`

const Title = styled.h2`
  font-size: 1.5em;
  letter-spacing: 0.04rem;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 1rem;
`
const Sub = styled.p`
  margin-bottom: 10px;
`
const Text = styled.span`
  line-height: 1.5;
  margin-bottom: 20px;
  margin-top: 1rem;
  white-space: pre-wrap;
`
