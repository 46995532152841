import styled from "styled-components";

const Card = ({ image, title, sub, text, setModalContent, setModalOpen }) => {
  const handleClickInfo = () => {
    setModalContent({ title: title, sub: sub, image: image, text: text });
    setModalOpen(true);
  };
  return (
    <>
      <div className="col">
        <Container className="card bg-dark gray-border">
          <ImgContainer>
            <Img className="card-img-top" src={image} alt=""></Img>
          </ImgContainer>

          <div className="card-body">
            <a
              className="text-light link-info bg-info text-decoration-none"
              href="#"
            >
              <Title className="fs-5 mb-0 heading fw-bold">{title}</Title>
            </a>
            <P className="text-light text-muted mb-2 lead">{sub}</P>
            <div className="d-flex justify-content-center">
              <button
                onClick={handleClickInfo}
                className="btn btn-info float-right"
              >
                More info
              </button>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default Card;
const Container = styled.div`
  border-radius: 8px;
`;
const ImgContainer = styled.div`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  min-height: 100%; /* or any specific value as per your design */
  width: 100%;
  height: auto;
  overflow: hidden;
`;
const Img = styled.img`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  min-height: 100%;
  width: 100%;
  height: auto;
  overflow: hidden;
`;
const Title = styled.h2`
  margin: 0;
  padding: 0; /* Set the maximum number of lines for the title */

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  
  -webkit-line-clamp: 3; /*  allows only 3 lines */
  -webkit-box-orient: vertical;
  white-space: normal;
  min-height: 2em;
`;
const P = styled.p`
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /*  max hight only 3 lines */
  -webkit-box-orient: vertical;
  white-space: normal;

  min-height: 4em; /* min hight 3 lines */
`;
