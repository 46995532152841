import styled from "styled-components"
import "./hero.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebook,
  faYoutube,
  faDiscord,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"
import { faHourglassHalf, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react"

const Component = ({ image, title, sub, text }) => {

  return (
    <>
      <Hero id="hero" className=" px-3 ">
        <div className="col col-md-6 d-flex align-items-center justify-content-center">
          <div></div>
        </div>
        <div className="container">
          <div className="row py-5">
            <div className="col-lg-1 d-none d-lg-flex align-items-center justify-content-center">
              <div
                className="lc-block d-flex"
                style={{
                  writingMode: "vertical-rl",
                  transform: "rotate(180deg)",
                }}
              >
                <Button
                  className="btn btn-link my-2  text-decoration-none"
                  href="#"
                  role="button"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    inverse
                    fixedWidth
                    style={{ color: "white", transform: "rotate(90deg)" }}
                    className="ms-1"
                    role="button"
                  />{" "}
                  Facebook
                </Button>
                <Button
                  className="btn btn-link my-4  text-decoration-none"
                  href="#"
                  role="button"
                >
                  <FontAwesomeIcon
                    icon={faTwitter}
                    inverse
                    fixedWidth
                    style={{ color: "white", transform: "rotate(90deg)" }}
                    className="ms-1"
                    role="button"
                  />{" "}
                  Twitter
                </Button>
                <Button
                  className="btn btn-link my-2  text-decoration-none"
                  href="#"
                  role="button"
                >
                  <FontAwesomeIcon
                    icon={faYoutube}
                    inverse
                    fixedWidth
                    style={{ color: "white", transform: "rotate(90deg)" }}
                    className="ms-1"
                    role="button"
                  />{" "}
                  Youtube
                </Button>
              </div>
            </div>
            <div className="col-sm-6 d-flex d-lg-none flex-sm-column align-items-center justify-content-around mb-4 mb-sm-0">
              <div className="lc-block">
                <Button
                  className="btn btn-link text-light text-decoration-none "
                  href="#"
                  role="button"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    inverse
                    fixedWidth
                    style={{ color: "white" }}
                  />{" "}
                  Facebook
                </Button>
              </div>
              <div className="lc-block">
                <Button
                  className="btn btn-link  text-decoration-none"
                  href="#"
                  role="button"
                >
                  <FontAwesomeIcon
                    icon={faTwitter}
                    inverse
                    fixedWidth
                    style={{ color: "white" }}
                  />{" "}
                  Twitter
                </Button>
              </div>
              <div className="lc-block">
                <Button
                  className="btn btn-link  text-decoration-none"
                  href="#"
                  role="button"
                >
                  <FontAwesomeIcon
                    icon={faYoutube}
                    inverse
                    fixedWidth
                    style={{ color: "white" }}
                  />{" "}
                  Youtube
                </Button>
              </div>
            </div>
            {/* hero image */}
            <div className="col-lg-4 col-sm-6 d-flex align-items-center justify-content-center">
              <div className="lc-block ratio ratio-1x1 ">
                <Img
                  style={{ objectFit: "cover" }}
                  className="img-fluid"
                  src={image}
                  alt="Photo of VCG logo."
                ></Img>
              </div>
            </div>
            <div className="col-lg-4 offset-lg-1">
              <div className="lc-block my-5">
                  <h2 className="rfs-25 display-4  heading">{title}</h2>
              </div>
              <div className="lc-block my-5">
                  <p className="">{text}</p>
              </div>
              <div className="lc-block">
              <a className="btn btn-primary btn-lg me-2" role="button">
                <FontAwesomeIcon 
                  icon={faHourglassHalf} 
                  fixedWidth 
                  style={{ color: "white" }}
                  />{" "}
                Sign Up Soon
              </a>
                <a className="btn btn-light btn-lg" href="#" role="button">
                  <FontAwesomeIcon
                    icon={faDiscord}
                    inverse
                    fixedWidth
                    style={{ color: "black" }}
                  />{" "}
                  Discord
                </a>
              </div>
              <div className="d-flex gap-2"></div>
            </div>
          </div>
        </div>
      </Hero>

    </>
  )
}
export default Component
const Hero = styled.div`
  min-height: 100vh;
  white-space: pre-wrap;
  font-size: 16px;
  background: linear-gradient(to bottom, black, #1a1a1f);
`
const Img = styled.img`
  border-radius: 8px;
  @media (max-width: 567px) {
  }
`
const Button = styled.a`
  border: none;
  text-decoration: none;
  color: white;
  &:focus,
  &:hover {
    border: none;
    outline: none;
    color: white;
    box-shadow: none;
  }
`
