import { useState } from "react"
import { NavLink } from "react-router-dom"
import logo from "../../images/common/vgc-text.png"
import styled from "styled-components"
import ModalBootstrap from "./ModalBootstrap"
import Rules from "./Rules"

const Navbar = () => {

  // rules modal

  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => {
    setModalOpen(true)
  }
  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <div
      className="container-fluid sticky-nav"
      style={{ padding: 0, margin: 0 }}
    >
      <Nav
        className="nav w-100 d-flex justify-content-between align-items-center bg-dark"
      >
        <NavLink className="navbar-brand" to="/">
          {/* logo */}
          <img
              alt={"VGC logo"}
            src={logo}
            style={{ marginBottom: "0.1rem", width: "100px" }}
              className="my-2 ms-3"
          ></img>
        </NavLink>
        {/* rules mobile */}
        <Link className="me-3" onClick={openModal}>
          Rules
        </Link>
      </Nav>

      {/* rules modal */}

      {modalOpen ? (
        <ModalBootstrap
          title={`VGC Rules and Regulations`}
          submitText={"Offer Contract"}
          closeModal={closeModal}
          submitButton={false}
          component={<Rules />}
        />
      ) : null}

    </div>
  )
}

export default Navbar

const Nav = styled.nav`
`

const Link = styled.div`
  color: grey;
  cursor: pointer;
  &:hover {
    color: #d3d3d3;
  }
`
