import styled from "styled-components"
import { rules } from "../../content-options/rules"

const Rules = () => {
  return (
    <>
      <Container >
        {rules
          ? Object.keys(rules).map((rule, i) => (
              <div key={i}>
                <Heading className="mt-4 heading fw-bold">{rule}</Heading>
                <Ul className="List-unstyled ">
                  {/*<Ol className="List-unstyled ">*/}
                    {rule && rules[rule]
                      ? [...Object.keys(rules[rule]).keys()].map((x, i) => (
                          <Li key={i}><BulletPoint className="heading">{i+1}.&nbsp;</BulletPoint>  {rules[rule][x + 1]}</Li>
                        ))
                      : null}
                  {/*</Ol>*/}
                </Ul>
              </div>
            ))
          : null}
      </Container>
    </>
  )
}

export default Rules

const Container = styled.div`
  /* text-align: start; */
  //width: 85vw;
  //max-width: 700px;
  @media (max-width: 600px) {
    //font-size: smaller;
  }
`
const Heading = styled.h3`
  letter-spacing: 0.02rem;
  //margin: 0 0.3rem 1rem 1.5rem;
  @media (max-width: 600px) {
    /* font-size: small; */
    font-weight: 600;
  }
`
const Ul = styled.div`
  margin: 0;
  //padding: 0 0 0 1.2rem;
`
const Ol = styled.ol`
  padding: 0;
`
const Li = styled.li`
  margin-top: 1rem;
  //padding-left: 0.3rem;
  list-style: none;
  
`
const BulletPoint = styled.span`
  color: rgb(190, 190, 190);
`

