import heroImage from "../../images/common/vgc-logo.png"


export const hero = {
  image:
    "https://cdn.discordapp.com/attachments/994175062011818055/1068173043995779082/herotest.png",
  title: "Welcome to the Virtual Gaming Community",
  sub: "",
  text: `This is a small Showcase of what is to come with the VGC website. Expected completion date: 15th November 2023
  `,
}
