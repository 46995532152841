import Navbar from "../../components/common/Navbar.js";
import styled from "styled-components";
import { useState } from "react";
import LandingPage from "../../components/landing/Landing.js";
import Footer from "../../components/common/Footer.js";

const Landing = ({}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);
  const [showLogin, setShowLogin] = useState(false);
  const toggleLogin = () => setShowLogin(!showLogin);

  return (
    <>
      <Navbar />

      <LandingPage />

      <Footer />
    </>
  );
};

export default Landing;

const ImgContainer = styled.div`
  max-width: 400px;
  aspect-ratio: 1/1;
`;
const Img = styled.img`
  width: 100%;
  height: 100%;
`;
