import styled from "styled-components";
import {  useEffect } from "react";
import Modal from "react-modal";

const customStyles = {
  overlay: {
    background: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0",
    padding: "0",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "999",
  },
  content: {
    backgroundColor: "#212121",
    borderRadius: "8px",
    width: "fit-content",
    boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.25)",
    border: ".5px gray solid",
    height: "fit-content",
    position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    background: "#222224",
    // padding: "3.5rem 0",
    zIndex: "999",
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: "90%",
    maxWidth: "95%",
    display: "flex",
    flexDirection: "column",
  },
};

const ReactModal = ({ modalOpen, component, closeModal }) => {
  // this may cause performance issues
  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [modalOpen]);

  return (
    <>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        className="react-modal"
        appElement={document.getElementById('root')}
      >
        <Button onClick={closeModal} className="btn btn-dark">
          X
        </Button>
        {component}
      </Modal>
    </>
  );
};
export default ReactModal;

const Button = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  font-weight: 800;
  border: 1px gray solid;

  /* makes space around button */

  /* cursor: pointer;
  font-weight: 800;
  margin: 1rem;
  float: right;
  aspect-ratio: 1/1;
  width: 35px;
  align-self: flex-end; */
`;
