const card1 = "https://cdn.discordapp.com/attachments/1014165365934264412/1162408311770075206/landing1.png"
const card2 = "https://cdn.discordapp.com/attachments/1014165365934264412/1162408312093028483/landing2.png"
const card3 = "https://cdn.discordapp.com/attachments/758733382154190928/1162565729648906310/image.png"
const card4 = "https://cdn.discordapp.com/attachments/758733382154190928/1162894450494808144/image.png"
const card5 = "https://cdn.discordapp.com/attachments/1014165365934264412/1162408313040941176/landing5.png"
const card6 = "https://cdn.discordapp.com/attachments/1014165365934264412/1162408313368088687/landing6.png"
const card7 = "https://cdn.discordapp.com/attachments/758733382154190928/1162586923064377434/vgc111.png"
const card8 = "https://cdn.discordapp.com/attachments/1014165365934264412/1162408313984659568/landing8.png"
const card9 = "https://cdn.discordapp.com/attachments/758733382154190928/1162577390287986708/image.png"

export const cards = [
  {
    image: card1,
    title: "What is VGC?",
    sub: "Find out who we are.",
    text: `<p>We're an exciting new esports company that's taking the gaming world by storm! Currently, we're a FIFA Pro Clubs league system, but we have big plans to expand to other games in the near future. Keep an eye out for updates!</p>` +
    `<p>Our website is user-friendly and packed with amazing features that you'll love exploring. We're all about creating a friendly and welcoming community, so we have strict rules in place to ensure that everyone feels safe and respected. Our dedicated and committed administration team works hard to enforce these rules and maintain a positive atmosphere.</p>` +
    `<p>Speaking of our staff team, they're some of the most motivated and helpful people you'll ever meet. They're passionate about making VGC one of the biggest and most successful esports leagues in the world, and they'll go above and beyond to make that happen. We also have managers and players who are eager to be a part of our journey to the top.</p>` +
    `<p>At VGC, we appreciate the hard work and dedication of everyone in our community, from admins to casual players. Our goal is to create a place where everyone can have a fun and fulfilling gaming experience, free from toxicity and negativity. Thanks for choosing VGC - we can't wait to see what the future holds!</p>`,
  },
  {
    image: card2,
    title: "Custom Manager Hub",
    sub: "We have created a fully customised managerial hub.",
    text: `Introducing the Custom Manager Hub - a powerful tool designed specifically for managers to help them manage their teams with ease. With this feature, managers have the ability to edit and change their rosters, access all our custom managerial features that standard players do not, and view league tables, fixtures, and rosters all in one convenient location. Submit lineups, manage your team to a very high standard with minimal work, and stay in control of your team's performance. Upgrade your management experience with the Custom Manager Hub.`,
  },
  {
    image: card3,
    title: "Commitment to Development",
    sub: "Our dedication to our community !",
    text: `<p>At VGC, our commitment to development is fueled by a dynamic team. Two dedicated students, alongside their exceptional tutor, who single-handedly constructed the entire VGC back end, are at the heart of our mission. These students bring fresh ideas and a passion for innovation, while their tutor provides invaluable guidance and expertise based on years of experience.</p>` +
    `<p>Our front-end developer, Shaun, stands out with unrivaled passion for coding and development. His creativity and dedication drive our front-end excellence.</p>` +
    `<p>The essence of our mission is to create the most competitive and equitable league system for Pro Clubs, setting new standards for professional gaming worldwide. Beyond this vision, we're resolute in expanding VGC into multiple games, striving to establish an esports dynasty that will captivate the world.</p>` +
    `<p>With a development team working tirelessly behind the scenes, we can swiftly adapt to community feedback and introduce new features to our websites. This unique capability sets us apart from other Pro Clubs leagues and positions us as a formidable force in the esports arena.</p>` +
    `<p>Our dedication to excellence remains steadfast, and our commitment to providing the best for our community is at the core of our mission. Together, we're pioneers, where innovation and passion converge to redefine the gaming landscape.</p>`,
  },
  {
    image: card4,
    title: "Paul's Statement",
    sub: "My commitment to creating the best enviroment for eSports.",
    text: `<p>"My mission is to lead VGC with unwavering commitment, determination, and a relentless pursuit of excellence. In 2018, when VGC faced its darkest hour, my passion and dedication to the gaming community were ignited. I embarked on a journey of self-improvement, learning website development from the ground up.</p>` +
    `<p>During my studies, fate brought two remarkable individuals into my life — a fellow student and an inspirational tutor. Together, we birthed VGC anew from the ashes of the past. In just 12 months, we have accomplished what many deemed astonishing, laying the foundation for a remarkable future.</p>` +
    `<p>Our vision is set, and nothing can deter us now. VGC will offer the most engaging and entertaining league formats, allowing users to savor the ultimate gaming experience. Swift, action-packed seasons will grant players more game time than ever before, while our flexibility in adding leagues and teams will host more competitors than we ever dreamed.</p>` +
    `<p>I am committed to nurturing the kindest, most sportsmanlike gaming community ever witnessed. With a zero-tolerance policy towards bullying in any form, I will act swiftly and decisively to maintain a harmonious environment within VGC.</p>` +
    `<p>I will ensure all staff within VGC follow our code of conduct and protect each and every member of our community. Our dream is to create the world's premier gaming platform, extending beyond EA FC24. It's time to fasten your seatbelts, for we are on the cusp of an incredible journey. VGC is preparing for takeoff, and I invite everyone to join us in shaping the future of gaming and community together."</p>`,
  },

  {
    image: card5,
    title: "Custom Trophies Room",
    sub: "Show your tropy wall off to your firends.",
    text: `Unlock the ultimate player achievement experience with our fully custom Trophy Wall. A first-of-its-kind feature, it brings the excitement of winning to the forefront, allowing users to see all the trophies they've won in real-time. Trophies that are yet to be won will be faded out, but once earned, they will become visible in all their glory. This feature is not only unique, but it's also a way for users to track their progress and career accolades on our platform. Alongside the Trophy Wall, we also have a Trophy Counter, which allows users to keep track of the total number of trophies they have won throughout their time on the Vgc platform. This feature is truly one of a kind, and it's sure to change the way you experience player achievements.`,
  },
  {
    image: card6,
    title: "Automated League Tables",
    sub: "Our custom league tables will always be up-to-date.",
    text: `Experience real-time league updates with our fully automated league tables. With our system, league tables update within seconds of both managers inputting a matching score, giving you the most up-to-date information possible. Our advanced technology ensures that the faster you are, the faster the league table is. This feature is not just efficient, but it is also visually appealing, with the use of color coding to highlight the different aspects of the league table. Green color highlights the top half of the table (teams fighting for trophies and promotion), Grey for the teams around the middle of the table looking to climb back up, and Red indicating the teams facing the prospect of relegation to the division below. This unique feature not only keeps you informed, but it also allows you to easily track the progress of your team and its competitors. Upgrade your league tracking experience and stay ahead of the game with our fully automated league tables.`,
  },
  {
    image: card7,
    title: "About our staff.",
    sub: "Meet the team !",
    text: `<p>At VGC, our staff team embodies the same unwavering dedication and values that underpin our community. Leading the charge is our Head Deputy, John, a seasoned professional with over a decade of experience in the clubs community, expertly guiding and overseeing the inner workings of VGC. He collaborates closely with Paul, our website's visionary creator.</p>` +
    `<p>Assisting John is Nick, our Deputy, who provides invaluable support and ensures seamless operations. Our team of Seniors, consisting of Chris D, Chris B, and Martin, diligently maintain the integrity of our leagues and the VGC community, ensuring that everything functions harmoniously.</p>` +
    `<p>In addition, our juniors play a vital role as our Discord moderation team and offer essential support to all members. Their commitment to upholding our community's values is unwavering.</p>` +
    `<p>Under John's expert leadership, our staff team operates diligently, adhering to VGC's practice and code of conduct, setting a high standard of professionalism for the benefit of our entire community.</p>`,
  },
  {
    image: card8,
    title: "Tracked Leaderboards",
    sub: "Keep track of all our leaderboards.",
    text: `Unlock the future of leaderboards with our fully automated stats system. Our cutting-edge technology tracks players' stats game by game, adding them to the leaderboards in real-time. The players at the top of the leaderboard will have a chance to win individual awards, including the prestigious Vgc d'Or, The Pinacle of Vgc eSports! As soon as a player uploads an image of their stats, our advanced system automatically reads and inputs the data, updating the leaderboards instantly. This feature is not only convenient, but it is also futuristic, giving you access to real-time, accurate and up-to-date information. Upgrade your leaderboard experience and take your gaming to the next level with our fully automated stats system.`,
  },
  {
    image: card9,
    title: "Have you joined our Discord server ?",
    sub: "Maybe now is the time too !",
    text: `Join our VGC Discord server! We're excited to welcome new members to our vibrant gaming community. If you're interested in becoming a manager and being part of our team, manager applications are currently open through our ticket system in the 'contact-us' channel. Click the link to join and be part of something extraordinary !`,
  },
];
