import { useState } from "react"
import Card from "./Card"
import ReactModal from "../common/ReactModal"
import Hero from "./Hero"
import { cards } from "../../content-options/landing/cards"
import { hero } from "../../content-options/landing/hero"
import ModalContent from "./ModalContent"

const Content = () => {
  const [modalContent, setModalContent] = useState("")
  const [modalOpen, setModalOpen] = useState(false)

  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      <ReactModal
        modalOpen={modalOpen}
        closeModal={closeModal}
        component={<ModalContent modalContent={modalContent} />}
      />
      <main>
        {hero ? (
          <Hero
            image={hero?.image}
            // image={heroImage}
            title={hero?.title}
            sub={hero?.sub}
            text={hero?.text}
          />
        ) : null}

        <div className="py-5 px-3">
          <div className="container">
            <div className="row g-4 row-cols-1 row-cols-sm-2 row-cols-md-3">
              {cards.map((card, i) => (
                <Card
                  key={i}
                  image={card.image}
                  title={card.title}
                  sub={card.sub}
                  text={card.text}
                  setModalContent={setModalContent}
                  setModalOpen={setModalOpen}
                />
              ))}
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Content
