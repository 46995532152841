export const rules = {
  "Profile and Conduct Rules": {
    1: "Players are encouraged to use the same username as their PSN or Xbox Network username, but it is not mandatory.",
    2: "All players are required to read and adhere to the code of conduct at all times.",
    3: "Harassment of any kind is not tolerated and will result in appropriate action being taken.",
    4: "If you have an issue or complaint, please contact a staff member privately and refrain from discussing it in public areas of the website. or Discord.",
    5: "Players are expected to honor and fulfill their agreements. Failure to do so may result in intervention from the website's administration.",
    6: "Advertising on the website is strictly prohibited and must be approved by the website's board of directors.",
    7: "Withholding information from staff regarding decisions may result in administrative action for dishonesty.",
  },
  "Format and Schedule Rules": {
    1: "The VGC schedule is set for Monday, Tuesday, and Wednesday at 19:00 BST and 19:30 BST.",
    2: "Players and managers are expected to adhere to the scheduled times and make every effort to be available for matches at these designated times.",
    3: "Rescheduling of matches is not permitted under any circumstances. All players and managers are expected to abide by the schedule as agreed upon upon accepting any contracts.",
  },
  "Gameplay Rules": {
    1: "Holding the ball and wasting time are not prohibited, as they are considered legitimate real-life football tactics. However, FIFA may intervene if the ball is held out of play for an excessive amount of time.",
    2: "Blocking the goalkeeper is not allowed, while positioning oneself close to the goalkeeper is permitted. A direct block of the goalkeeper's path to the ball is considered an illegal offense.",
    3: "Using penalty glitches during games is not permitted.",
    4: "Scoring goals on kick-off with speed-up lag is not allowed.",
    5: "Player kit names must be respectful and must reference the player controlling the character in the game.",
    6: "If a team is caught using players who are not listed on the team roster, disciplinary action will be taken.",
    7: "The starting lineup is determined by the manager and any formation may be used.",
    8: "Position and formation changes can be made at any point during the game, but players must input the stats image for the position they played on in the website's starting lineup.",
    9: "The home team (host) must invite the away team to the match via a friendly. Both teams will invite each other during the season, as teams meet twice in a season. This ensures a fair playing field in case of lag or latency issues.",
    10: "Match time can be called on the website only. If time is called via Discord and not on the website, the game's result will not count unless the other manager agrees to it. The in-house call time feature is in place to protect managers and clubs from default losses. ",
    11: "Holding the ball and wasting time are not prohibited, as they are considered legitimate real-life football tactics. However, FIFA may intervene if the ball is held out of play for an excessive amount of time.",
  },
  "Player Kit Name Policy": {
    1: "Player kit names must be respectful and must reference the player controlling the character in the game. Use of offensive or inappropriate language or references will not be tolerated.",
  },
  "Team Roster Rules": {
    1: "Managers are responsible for maintaining and managing their team's roster.",
    2: "Only players listed on the team's roster are eligible to participate in scheduled games.",
    3: "Any use of unlisted players will result in disciplinary action.",
    4: "Managers have the authority to add, remove or update players on their team's roster as necessary.",
    5: "It is the manager's responsibility to ensure that the roster is accurate and up-to-date at all times.",
    6: "Team's rosters must be filled with actual players of the team, using fake players or ringers will result in disciplinary action.",
  },
  "Complaint Handling Rules": {
    1: "All complaints must be submitted through our dedicated Discord channel for complaints.",
    2: "Complaints must be submitted with clear and specific details of the issue, including any relevant evidence or screenshots.",
    3: "Complaints must be submitted in a respectful and professional manner. Any personal attacks or harassment directed towards staff or other players will not be tolerated.",
    4: "The dedicated staff team will review and investigate all complaints in a timely manner.",
    5: "The outcome of a complaint may result in disciplinary action, such as a warning, suspension or termination of membership, depending on the severity of the issue.",
    6: "All decisions made by the staff team regarding complaints are final.",
    7: "TThe staff team reserves the right to dismiss any complaint that is deemed frivolous or without merit.",
    8: "Complaints about staff members should be submitted to a higher-ranking staff member or a member of the management team.",
  },
  "Manager Roster Management Rules": {
    1: "Managers have discretion over who plays and when within their team, and are not required to provide game time to all players on their roster if they have better options available.",
    2: "Managers may sign players at any time during, before, and after a season. Transfer windows are not in place.",
    3: "Players must be released from their contract or self-release before they can join another team in the same time zone or league for the stated continent.",
    4: "Participation in pre-season tournaments hosted by VGC is mandatory for VGC teams and managers. These tournaments serve as an evaluation of skill level before the start of a season.",
    5: "Managers with premium membership have the right to release players at any time. Without premium membership, players must fulfill their contract unless they purchase premium themselves.",
  },
  "Player Rights and Responsibilities": {
    1: "Players who are contracted to a club are expected to give their full commitment to the club and the manager's vision for the team.",
    2: "Players may choose to renew their contract with a team after its expiration or request to be released from their contract.",
    3: "The decision to accept or reject a contract offer is the sole responsibility of the player and should not be influenced by outside parties.",
    4: "Players have the option to purchase premium membership and release themselves from any contracts they are bound to.",
    5: "Managers with premium membership have the right to release players at any time. Without premium membership, players must fulfill their contract unless they purchase premium themselves.",
  },
};
