import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faYoutube,
  faDiscord,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import ModalBootstrap from "./ModalBootstrap";
import Rules from "./Rules";
import {policies} from "../../content-options/landing/policies"
import {useState} from "react";

const Footer = ({}) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [ policy, setPolicy ] = useState("terms")

  const handleClickPolicy= (name) => {
    setPolicy(name)
    openModal()
  }

  // bootstrap modal

  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => {
    setModalOpen(true)
  }
  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      <footer className="bg-dark mt-5">
        <div className="py-5 ">
          <div className="container">
            <div className="container-fluid text-left" style={{ width: "fit-content"}}>
            <ul className="nav justify-content-center fs-5">
              <li className="nav-item">
                <a
                  className="nav-link d-flex align-items-center text-decoration-none text-white link-info"
                  href="https://discord.gg/pbzhSxQde"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faDiscord} inverse fixedWidth />
                  <span className="ps-1">Discord</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link d-flex align-items-center text-decoration-none text-white link-info"
                  href="https://www.facebook.com/groups/1304744652991863"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faFacebook} inverse fixedWidth />
                  <span className="ps-1">Facebook</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link d-flex align-items-center text-decoration-none text-white link-info"
                  href=""
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faTwitter} inverse fixedWidth />
                  <span className="ps-1">Twitter</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link d-flex align-items-center text-decoration-none text-white link-info"
                  href="https://www.youtube.com/channel/UCPDPALfTJQg1TRL8cPxgb4g"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faYoutube} inverse fixedWidth />
                  <span className="ps-1">YouTube</span>
                </a>
              </li>
            </ul>
              {/* Terms of service */}
              <ul className="terms-of-service list-unstyled mt-5 " >
                <li className="nav-item d-flex justify-content-center">
                    <span className="nav-link "><a className="text-decoration-none  text-muted cursor-pointer cursor-pointer"  onClick={() => handleClickPolicy("terms")}>Terms of Service</a></span>
                </li>
                <li className="nav-item d-flex justify-content-center">
                    <span className="nav-link text-decoration-none text-white "><a className="text-decoration-none text-muted cursor-pointer"  onClick={() => handleClickPolicy("privacy")}>Privacy Policy</a></span>
                </li>
                <li className="nav-item d-flex justify-content-center">
                    <span className="nav-link text-decoration-none text-white"><a className="text-decoration-none text-muted cursor-pointer" onClick={() => handleClickPolicy("cookie")}>Cookie Policy</a></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className=" px-3 px-sm-0 ">
          <div className="container pb-3">
            <div className="d-flex align-items-center">
              <div className="w-100 small m-0">
                ©2023 VGC (virtual gaming community).
              </div>
              <div className="flex-shrink-1 ms-4">
                <button
                  type="button"
                  className="btn btn-dark btn-sm "
                  onClick={scrollToTop}
                >
                  <FontAwesomeIcon icon={faArrowUp} inverse fixedWidth />
                </button>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/*bootstrap modal*/}
      {modalOpen ? (
          <ModalBootstrap
              title={policy === "terms" ? `VGC Terms of Service` : policy === "privacy" ? `VGC Privacy Policy` : policy === "cookie" ? `VGC Cookie Policy` : null}
              // display={true}
              closeModal={closeModal}
              submitButton={false}
              html={policies ? policies[policy] : null}
          />
      ) : null}
    </>
  );
};
export default Footer;
